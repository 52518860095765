<template>
	<v-container>
		<h1>Групи</h1>

		<v-card
			outlined>
			<v-data-table
				:headers="headers"
				:items="groups"
				:items-per-page="15"
				:search="search"
				:loading="loading"
				loading-text="Завантаження груп... Зачекайте, будь ласка"
				no-data-text="Немає жодної групи">

				<template #top>
										
					<v-toolbar
						color="transparent"
						flat>
						<v-dialog
							v-model="createDialog"
							max-width="800px"
							persistent
							:fullscreen="$vuetify.breakpoint.xsOnly">

							<v-card
								outlined
								:loading="loading">
								<validation-observer
									ref="observer"
									v-slot="{ invalid, handleSubmit }">
									<v-form ref="editForm"
										@submit.prevent="handleSubmit(saveGroup)"
										@reset.native="cancelEditForm">

										<v-card-title>Група</v-card-title>
										<v-divider></v-divider>

										<v-card-text>
											<v-row>
												<v-col
													cols="12"
													md="6"
													class="py-0">
													<vv-text-field
														v-model="group.name"
														label="Назва групи"
														rules="required"
														name="name"
														required
														outlined
														dense>
													</vv-text-field>
												</v-col>
												<v-col
													cols="12"
													md="6"
													class="py-0">
													<vv-autocomplete
														v-model="group.course_id"
														:items="courses"
														item-text="name"
														item-value="id"
														label="Курс"
														name="course_id"
														outlined
														dense>
													</vv-autocomplete>
												</v-col>
												<v-col
													cols="12"
													md="6"
													class="py-0">
													<vv-autocomplete
														v-model="group.teachers"
														:items="teachers"
														item-text="full_name"
														item-value="id"
														label="Вчитель"
														name="teacher_id"
														deletable-chips
														return-object
														small-chips
														multiple
														outlined
														chips
														dense>
													</vv-autocomplete>
												</v-col>
												<v-col
													cols="12"
													class="py-0">
													<vv-autocomplete
														v-model="group.users"
														:items="users"
														label="Учні"
														item-text="full_name"
														item-value="id"
														rules="required"
														name="users"
														deletable-chips
														return-object
														hide-selected
														chips
														small-chips
														multiple
														required
														outlined
														dense>

														<template #item="{ item }">
															<v-list-item-content>
																<v-list-item-title>
																	{{ item.full_name }}

																	<v-chip
																		v-for="(course, key) in item.user_courses"
																		:key="key"
																		x-small>
																		курс: {{ course.name }}
																	</v-chip>
																</v-list-item-title>
															</v-list-item-content>
														</template>

													</vv-autocomplete>
												</v-col>
											</v-row>

											<v-list>
												<v-list-item-group>
													<v-list-item
														v-for="user in group.users"
														:key="user.id"
														:to="{
															name: 'user',
															params: {
																userid: user.id,
															},
														}">

														<v-avatar>
															<v-icon>
																mdi-account-circle
															</v-icon>
														</v-avatar>
																
														<v-list-item-content>
															<v-list-item-title>
																{{ user.full_name }}
																{{ user.full_name1 }}
															</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</v-card-text>

										<v-divider></v-divider>
										<v-card-actions>
											<v-spacer></v-spacer>
											<v-btn
												depressed
												type="reset">
												Відмінити
											</v-btn>
											<v-btn
												color="primary"
												depressed
												type="submit"
												:disabled="invalid">
												Зберегти
											</v-btn>
										</v-card-actions>
									</v-form>
								</validation-observer>
							</v-card>
						</v-dialog>

						<v-btn
							color="success"
							depressed
							small
							@click="addGroup">
							<v-icon left>mdi-plus</v-icon>
							Додати нову групу
						</v-btn>

						<v-spacer></v-spacer>

						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Пошук"
							single-line
							hide-details
							outlined
							dense>
						</v-text-field>

					</v-toolbar>
				</template>

				<template #item.action="{ item }">
					<v-btn
						color="warning"
						icon
						@click="editGroup(item)">
						<v-icon
							small>
							mdi-pencil
						</v-icon>
					</v-btn>

					<v-btn
						color="error"
						icon
						@click="deleteGroup(item)">
						<v-icon
							small>
							mdi-delete
						</v-icon>
					</v-btn>
				</template>

			</v-data-table>
		</v-card>

		<app-confirm ref="confirm"></app-confirm>
		
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import User from '../models/User'
import Teacher from '../models/Teacher'
import Course from '../models/Course'
import Group from '../models/Group'

export default {
	data: () => ({
		group: {},
		headers: [
			{
				text: 'Назва групи',
				value: 'name',
			},
			{
				text: 'Курс',
				value: 'course.name',
			},
			{
				text: 'Кількість учнів',
				value: 'users_count',
			},
			{
				text: 'Дії',
				value: 'action',
				sortable: false,
				align: 'center',
				width: '10em',
			},
		],
		search: '',
		loading: false,
		createDialog: false,
	}),
	computed: {
		users() {
			return User.all()
		},
		teachers() {
			return Teacher.all()
		},
		courses() {
			return Course.all()
		},
		groups() {
			return Group.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadUsers() {
			try {
				await User.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список учнів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadTeachers() {
			try {
				await Teacher.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список викладачів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadCourses() {
			try {
				await Course.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список курсів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadGroups() {
			try {
				this.loading = true
				await Group.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список груп',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},
		
		async saveGroup() {
			try {
				this.loading = true
				await Group.fetchCreateOrUpdate(this.group)
				this.$refs.editForm.reset()
				this.$refs.observer.reset()
				this.createDialog = false
				this.notify({
					text: 'Групу успішно збережено',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося зберегти групу',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async deleteGroup(group) {
			try {
				const confirm = await this.$refs.confirm.open(
					'Видалити групу',
					'Ви дійсно хочете видалити групу? Цю дію неможливо відмінити.',
					{ color: 'red' })
				if (confirm) {
					this.loading = true
					await Group.fetchDelete(group.id)
					this.notify({
						text: 'Групу видалено',
						color: 'success',
					}, { root: true })
				}
			} catch (error) {
				this.notify({
					text: 'Не вдалося видалити групу',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		cancelEditForm() {
			this.group = new Group()
			this.$refs.editForm.reset()
			this.$refs.observer.reset()
			this.createDialog = false
		},
		addGroup() {
			this.group = new Group()
			this.createDialog = true
		},
		editGroup(item) {
			this.group = new Group(item)
			this.createDialog = true
		},
	},
	mounted() {
		this.loadUsers()
		this.loadTeachers()
		this.loadCourses()
		this.loadGroups()
	},
}
</script>